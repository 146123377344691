import React from "react"
import { HeaderContainer, Menu } from "./styles"
import Logo from "../Logo"
import { TextMenu } from "../atomics"
import { Link } from "gatsby"
import { useState } from "react"
import { FaInstagram } from "react-icons/fa"
import { MdBrightnessHigh, MdBrightnessLow } from "react-icons/md"
import menuIcon from "../../assets/images/menu.png"
import { useParams } from "../../hooks"

function Header({ title, logo, theme, setTheme ,primaryColor }) {
  const [menuOpen, setMenu] = useState(false)
  const { query, url } = useParams()
  function setCurrentTheme(t) {
    localStorage.setItem("theme", t)
    setTheme(t)
  }
  return (
    <HeaderContainer>
      <div className="header-logo">
        <Logo title={title} logo={logo} primaryColor={primaryColor} />
        <div
          onClick={() => setMenu(!menuOpen)}
          aria-hidden="true"
          role="button"
        >
          <img src={menuIcon} alt="menu" className="icon-menu" />
        </div>
      </div>
      <Menu menuOpen={menuOpen}>
        <Link to={"/apps" + query}>
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('apps')}>APPS</TextMenu>
        </Link>

        <Link to={"/combos" + query}>
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('combos')}>COMBOS</TextMenu>
        </Link>

        <Link to="/cursos">
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('cursos')}>CURSOS</TextMenu>
        </Link>
        
        <Link to="/ebooks">
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('ebooks')}>EBOOKS</TextMenu>
        </Link>

        <Link to={"/newsletter" + query}>
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('newsletter')}>NEWSLETTER</TextMenu>
        </Link>
        
        <Link to="/blog">
          <TextMenu className="item-menu" primaryColor={primaryColor} selected={url.includes('blog')}>BLOG</TextMenu>
        </Link>


        <Link
          to="https://instagram.com/appflynow"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <TextMenu className="item-menu" primaryColor={primaryColor}>
            <FaInstagram size={35} />
          </TextMenu>
        </Link>
        <TextMenu className="item-menu" primaryColor={primaryColor}>
          {theme === "default" ? (
            <MdBrightnessHigh
              size={35}
              onClick={() => setCurrentTheme("dark")}
            />
          ) : (
            <MdBrightnessLow
              size={35}
              onClick={() => setCurrentTheme("default")}
            />
          )}
        </TextMenu>
      </Menu>
    </HeaderContainer>
  )
}

export default Header
